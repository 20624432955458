/**
 * @file This file manage the Swiper slides in different pages
 **/

'use strict';

var Swiper = require('swiper'); // NPM Swiper Library
var util = require('./util.js');
var accessibility = (util.isMobile()) ? require('./accessibilityForMobile') : require('./accessibility');
var trackingHelper = require('./tracking/trackingHelper');
var keyboard = require('./constants').keyboard;
var video = require('./video');
var tooltip = require('./tooltip');

var pdpSwipers = {
	mainImageSwiper: null,
	zoomImageSwiper: null
};

/**
 * @function
 * @description Initializes the swiper
 */
exports.init = function () {
	initializePdpSwiper();
	initializeHeaderUspSwiper();
	initializeRightPdpSwiper();
	initializePageDesignerSwiper();
	initializePageDesignerSwiperImageGrid();
	initializePageDesignerBannerCarousel();
	initializePageDesignerHeroSlider();
	initializePageDesignerCategoryThumbnail();
	if (!$('body').hasClass('show-filter')) {
		initializeProductTileSwiper();
	}
	else {
		$('body').one('filtersclosed', initializeProductTileSwiper);
	}

	initializeProductRecommendationSwiper();
	initShoeCareRecommendationSwiper();
	initShopTheLookRecommendationSwiper();
	initInspirationProductsSwiper();
	initPdpDesignGallerySwiper();
	initMeasurementSwiper();
	initPageDesignerSwiperJournalSlider();
	initializeCartReminder();
	initStoryBlockSlider();
	initializeBestSellersBlockSwiper();
	initPorductHotspotSlider();
};

/**
* @function
* @description Product Hotspot slider
*/

function initPorductHotspotSlider() {
	var swiperContainer = $('[data-js="product-hotspot-slider"]');
	if (swiperContainer.length) {
		swiperContainer.each(function(index, swiperEl) {
			var hotspotSlider = new Swiper(swiperEl, {
				slidesPerView: '1',
				loop: true,
				navigation: {
					nextEl: $('[data-js="product-hotspot-right"]', swiperEl),
					prevEl: $('[data-js="product-hotspot-left"]', swiperEl),
				},
				on: {
					init: function() {
						var swiper = this;
						var hotspotButton = $('[data-js="product-hotspot-button"]');
						hotspotButton.on('keydown', function(e) {
							if (e.keyCode === keyboard.ENTER_KEY) {
								e.preventDefault();
								var slideIndex = $(this).data('slide-index');
								swiper.slideToLoop(slideIndex);
								hotspotButton.removeClass('active');
								$(this).addClass('active');
							}
						});

						hotspotButton.on('click', function() {
							var slideIndex = $(this).data('slide-index');
							swiper.slideToLoop(slideIndex);
							hotspotButton.removeClass('active');
							$(this).addClass('active');
						});
					},
					transitionEnd: function() {
						var swiper = this;
						var hotspotIndex = swiper.$el.find('[data-js="product-hotspot-nav-index"]');
						var sliderIndex = swiper.realIndex + 1;
						var hotspotButton = $('[data-js="product-hotspot-button"]');
						$(hotspotIndex).html('0' + sliderIndex);
						hotspotButton.each(function () {
							if ($(this).data('slide-index') === swiper.realIndex) {
								$(this).addClass('active');
							}
							else {
								$(this).removeClass('active');
							}
						});
					}
				}
			});
		});
	}
}

/**
* @function
* @description Products Swiper (Inspiration page)
*/
function initInspirationProductsSwiper() {
	if ($('.js-inspiration-product-slider').length) {
		var inspirationProductsSwiper = new Swiper('.js-inspiration-product-slider', {
			slidesPerView: 'auto',
			simulateTouch: false, // To disable touch on desktop
			breakpoints: {
				// when window width is <= 767px
				767: {
					loop: true
				},

				1024: {
					simulateTouch: true
				}
			},
			navigation: {
				nextEl: '.inspiration-controller-next',
				prevEl: '.inspiration-controller-prev'
			},
		});
	}
}

/**
* @function
* @description Measurement Swiper (Product detail Page)
*/
function initMeasurementSwiper() {
	if ($('.js-measurement-slider').length) {
		var measurementSwiper = new Swiper('.js-measurement-slider', {
			slidesPerView: 'auto',
			simulateTouch: false, // To disable touch on desktop
			breakpoints: {
				// when window width is <= 767px
				767: {
					loop: true
				},

				1024: {
					simulateTouch: true
				}
			},
		});
	}
}

/**
* @function
* @description  Design Gallery Swiper (Product detail Page)
*/
function initPdpDesignGallerySwiper() {
	var pdpDesignGallerySwiper = new Swiper('.js-design-gallery-slider', {
		loop: true,
		slidesPerView: 'auto',
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		}
	});
}

function initShoeCareRecommendationSwiper() {
	var shoeCareRecommendationSwiper = new Swiper('[data-js="shoe-care-recommendation-swiper"]', {
		loop: false,
		spaceBetween: 1,
		slidesPerView: 'auto',
		simulateTouch: true,
		breakpoints: {
			0: {
				slidesPerView: 1.3,
			},
			// when window width is >= 481px
			481: {
				slidesPerView: SitePreferences.IS_RESKIN ? 3.5 : 2.5,
			},
			// when window width is >= 1001px
			1001: {
				slidesPerView: 4.5,
			}
		},
		navigation: {
			nextEl: '[data-js="shoe-care-recommendation-swiper-next"]',
			prevEl: '[data-js="shoe-care-recommendation-swiper-prev"]'
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
			renderBullet: function (index, className) {
				return '<span class="' + className + '"></span>';
			},
		},
	});

	const numberOfProducts = $('[data-js="shoe-care-recommendation-swiper"] .swiper-wrapper').find('.swiper-slide').length;

	if ((util.isDesktopAndAbove() && numberOfProducts <= 4)
		|| (util.isTabletAndBelow() && !util.isMobileAndBelow() && numberOfProducts <= 2)
		|| (util.isMobileAndBelow() && numberOfProducts <= 1)) {
		$('[data-js="shoe-care-recommendation-swiper"] .js-swiper-pagination').hide();
	}
}

function initShopTheLookRecommendationSwiper() {
	var shopTheLookeRecommendationSwiper = new Swiper('[data-js="shopthelook-recommendation-swiper"]', {
		slidesPerView: 'auto',
		simulateTouch: true,
		loop: true,
		breakpoints: {
			// when window width is >= 420px
			420: {
				slidesPerView: 1,
			},
			// when window width is >= 590px
			590: {
				slidesPerView: 3,
			},
			// when window width is >= 1024px
			1024: {
				slidesPerView: 4,
			}
		},
		navigation: {
			nextEl: '[data-js="shopthelook-recommendation-swiper-next"]',
			prevEl: '[data-js="shopthelook-recommendation-swiper-prev"]'
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
			renderBullet: function (index, className) {
				return '<span class="' + className + '"></span>';
			},
		},
	});
}

/**
* @function
* @description Product Recommendation Swiper
*/

function initializeProductRecommendationSwiper() {
	if ($('[data-js="product-recommendation-swiper"]').length) {
		initializeRecommendationSwiper();
	}
	//in case that we are using einstein recommendations that part of the page will be loaded separately
	$(document).off('recommendationswiperloaded', '[data-js="product-recommendation-swiper"]')
		.on('recommendationswiperloaded', '[data-js="product-recommendation-swiper"]', initializeRecommendationSwiper);
}

var initializedRecommendationSwipers = {};

function initializeRecommendationSwiper() {
	var swiperContainer = $('[data-js="product-recommendation-swiper"]');

	$(swiperContainer).each(function (index, swiperEl) {
		// first we check if the recommendation swiper initialized before or not"
		if (initializedRecommendationSwipers[swiperEl.getAttribute('id')]) {
			var prevSwiper = initializedRecommendationSwipers[swiperEl.getAttribute('id')];
			if (prevSwiper) {
				// if it is initialized before then we destroy it first and then it will be initialized again below
				// initializing a swiper multiple times causes some issues with the slider carousel (i.e. pagination on mobile doesn't change with tocuh events)
				prevSwiper.destroy(true, true);
			}
		}

		var currentEinsteinCarousel = swiperContainer[index];
		var currentPaginationContainer = $(currentEinsteinCarousel).find('.swiper-pagination');
		var currentNextEl = $(currentEinsteinCarousel).find('[data-js="product-recommendation-swiper-next"]');
		var currentPrevEl = $(currentEinsteinCarousel).find('[data-js="product-recommendation-swiper-prev"]');

		var defaultOptions = {
			slidesPerView: 'auto',
			simulateTouch: true,
			breakpoints: {
				// when window width is >= 420px
				420: {
					slidesPerView: 1,
					loop: true,
				},
				// when window width is >= 590px
				590: {
					slidesPerView: 3,
				},
				// when window width is >= 1024px
				1024: {
					slidesPerView: 4,
				}
			},
			navigation: {
				nextEl: currentNextEl,
				prevEl: currentPrevEl
			},
			pagination: {
				el: currentPaginationContainer,
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
				},
			},
		};

		var similarProductOptions = {
			slidesPerView: 'auto',
			simulateTouch: true,
			breakpoints: {
				// when window width is >= 420px
				420: {
					slidesPerView: 2,
					loop: true,
				},
				// when window width is >= 590px
				590: {
					slidesPerView: 3,
				},
				// when window width is >= 1024px
				1024: {
					slidesPerView: 6,
				}
			},
			navigation: {
				nextEl: currentNextEl,
				prevEl: currentPrevEl
			},
			pagination: {
				el: currentPaginationContainer,
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
				},
			},
		};

		var cartRecommendationOptions = {
			slidesPerView: 'auto',
			simulateTouch: true,
			spaceBetween: 1,
			breakpoints: {
				0: {
					slidesPerView: 1.3,
				},
				// when window width is >= 481px
				481: {
					slidesPerView: 2.5,
				},
				// when window width is >= 1001px
				1001: {
					slidesPerView: 4.5,
				}
			},
			pagination: {
				el: currentPaginationContainer,
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
				},
			},
		};

		var productRecommendationSwiper;
		if ($(currentEinsteinCarousel).data('sliderType') === 'similar-items') {
			const productItems = currentEinsteinCarousel.querySelectorAll('.swiper-slide').length;
			productRecommendationSwiper = new Swiper(currentEinsteinCarousel, similarProductOptions);

			if (productItems > 6) {
				currentEinsteinCarousel.classList.add('c-products-list-recomm--start');
				currentEinsteinCarousel.swiper.on('slideChange', () => {
					const isEnd = currentEinsteinCarousel.swiper.isEnd;
					const isBeginning = currentEinsteinCarousel.swiper.isBeginning;
					if (isEnd === true) {
						currentEinsteinCarousel.classList.add('c-products-list-recomm--end');
						currentEinsteinCarousel.classList.remove('c-products-list-recomm--start');
						currentEinsteinCarousel.classList.remove('c-products-list-recomm--both');
					}
					if (isBeginning === true) {
						currentEinsteinCarousel.classList.add('c-products-list-recomm--start');
						currentEinsteinCarousel.classList.remove('c-products-list-recomm--end');
						currentEinsteinCarousel.classList.remove('c-products-list-recomm--both');
					}
					if (isEnd !== true && isBeginning !== true) {
						currentEinsteinCarousel.classList.add('c-products-list-recomm--both');
						currentEinsteinCarousel.classList.remove('c-products-list-recomm--start');
						currentEinsteinCarousel.classList.remove('c-products-list-recomm--end');
					}
				});
			}
		}
		else {
			if (!$(currentEinsteinCarousel).hasClass('js-cart-recommendations')) {
				productRecommendationSwiper = new Swiper(currentEinsteinCarousel, defaultOptions);
			}
			else {
				productRecommendationSwiper = new Swiper(currentEinsteinCarousel, cartRecommendationOptions);
			}
		}

		const numberOfProducts = $(`#${swiperEl.getAttribute('id')} .swiper-wrapper`).find('.swiper-slide').length;

		if ((util.isDesktopAndAbove() && numberOfProducts <= 4)
			|| (util.isTabletAndBelow() && !util.isMobileAndBelow() && numberOfProducts <= 2)
			|| (util.isMobileAndBelow() && numberOfProducts <= 1)) {
			$('.js-cart-recommendations .js-swiper-pagination').hide();
		}

		// we push initialized recommendation swiper into an object
		initializedRecommendationSwipers[swiperEl.getAttribute('id')] = productRecommendationSwiper;
	});
}

function initializeBestSellersBlockSwiper() {
	if ($('[data-js="best-sellers-swiper"]').length) {
		initializeBestSellersSwiper();
	}
	//in case that we are using einstein recommendations that part of the page will be loaded separately
	$(document).off('bestsellersswiperloaded', '[data-js="best-sellers-swiper"]')
		.on('bestsellersswiperloaded', '[data-js="best-sellers-swiper"]', initializeBestSellersSwiper);
}

function initializeBestSellersSwiper() {
	var swiperContainer = $('[data-js="best-sellers-swiper"]');
	var tabIndexSelector = $('.highlighted-product-text').find('.product-name');
	// TODO: The markup has already a tabindex="0" but we need to change to tabindex="1" to force
	// the focus via keyboard, we need a specific ticket to fix this.
	tabIndexSelector.attr('tabindex', '1');

	$(swiperContainer).each(function (index, swiperEl) {
		var currentEinsteinCarousel = swiperContainer[index];
		var currentPaginationContainer = $(currentEinsteinCarousel).find('.swiper-pagination');
		var currentNextEl = $(currentEinsteinCarousel).find('[data-js="best-sellers-swiper-next"]');
		var currentPrevEl = $(currentEinsteinCarousel).find('[data-js="best-sellers-swiper-prev"]');

		var bestSellersSwiper = new Swiper(currentEinsteinCarousel, {
			slidesPerView: 'auto',
			simulateTouch: false,
			breakpoints: {
				// when window width is >= 420px
				320: {
					slidesPerView: 2,
				},
				// when window width is >= 590px
				600: {
					slidesPerView: 2,
				},
				// when window width is >= 1024px
				1024: {
					slidesPerView: 4
				}
			},
			navigation: {
				nextEl: currentNextEl,
				prevEl: currentPrevEl
			},
			pagination: {
				el: currentPaginationContainer,
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
				},
			},
		});
	});
}

/**
 * @function
 * @description Initializes Page Designer Story Block slider only on mobile
 */

function initStoryBlockSlider() {
	var swiperContainer = $('[data-js="story-block"]');
	var swiperContainerPagination = ('[data-js="story-block-pagination"]');

	if (util.isSmallScreenSize() && swiperContainer.hasClass('with-slider')) {
		$(swiperContainer).each(function (index, swiperEl) {
			var currentSwiperContainer = swiperContainer[index];
			var storyCarousel = new Swiper(currentSwiperContainer, {
				autoHeight: true,
				slidesPerView: 1,
				loop: true,
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 1,
				},
				keyboard: {
					enabled: true,
				},
				pagination: {
					el: swiperContainerPagination,
					clickable: true,
					renderBullet: function (index, className) {
						return '<span class="' + className + '"></span>';
					},
				}
			});
		});
	}
}

/**
 * @function
 * @description Initializes the swiper in the Pagedesigner Journal Slider component
 */
function initPageDesignerSwiperJournalSlider() {

	var swiperContainer = $('[data-js="js-journal-slider"]');

	$(swiperContainer).each(function (index, swiperEl) {

		var currentSwiperContainer = $('[data-js="js-journal-slider"]')[index];
		var container = $(currentSwiperContainer).find('[data-js="sliders-container"]');
		var wrapper = container.find($('.swiper-wrapper'));
		var listSize = wrapper.children().length;
		var prevButton = container.find($('[data-js="swiper-prev"]'))
		var nextButton = container.find($('[data-js="swiper-next"]'))

		// add is-not-slider class if there are less then 3 tiles and screenwitdh is more then 1024px
		if (listSize <= 3 && window.innerWidth >= 1024) {
			$(currentSwiperContainer).addClass('is-not-slider');
		}

		var journalSlider = new Swiper(container, {
			loop: false,
			spaceBetween: 0,
			centeredSlides: false,
			a11y: true,
			keyboardControl: true,
			slidesPerView: 1,
			navigation: {
				nextEl: $('[data-js="swiper-next"]', swiperEl),
				prevEl: $('[data-js="swiper-prev"]', swiperEl)
			},
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 2,
			},
			pagination: {
				el: '.journal-swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
				},
			},
			breakpoints: {
				1024: {
					slidesPerView: 3,
					spaceBetween: 20,
				}
			}
		});

		// calculation swiper prev/next buttons to center according to image size
		var swiperImageHeight = container.find($('.slider-item-image')).height();
		var swiperButtonPos = swiperImageHeight / 2;
		nextButton.css('top', swiperButtonPos);
		prevButton.css('top', swiperButtonPos);
	});

	$(swiperContainer).each(function () {
		var container = $(this);
		var wrapper = container.find($('.swiper-wrapper'));
		var listSize = wrapper.children().length;

		var prevButton = container.find($('[data-js="swiper-prev"]'))
		var nextButton = container.find($('[data-js="swiper-next"]'))

		// add is-not-slider class if there are less then 3 tiles and screenwitdh is more then 1024px
		if (listSize <= 3 && window.innerWidth >= 1024) {
			container.addClass('is-not-slider');
		}

		// calculation swiper prev/next buttons to center according to image size
		var swiperImageHeight = container.find($('.slider-item-image')).height();
		var swiperButtonPos = swiperImageHeight / 2;
		nextButton.css('top', swiperButtonPos);
		prevButton.css('top', swiperButtonPos);
	});
}

/**
 * @function
 * @description Initializes the swiper in the Pagedesigner Image Grid
 */

function initializePageDesignerSwiperImageGrid() {
	var swiperMobile = '[data-js="imagegrid-swiper"]';
	var swiperDesktop = '[data-js-desktop="imagegrid-swiper-desktop"]';

	if (util.isTabletAndBelow() && $(swiperMobile).length ) {
		var imageGridSwiper = new Swiper(swiperMobile, {
			loop: false,
			slidesPerView: 'auto',
			simulateTouch: true,
			breakpoints: {
				0: {
					slidesPerView: 1.5,
				},
				// when window width is >= 481px
				481: {
					slidesPerView: 2.5,
				}
			},
			pagination: {
				el: '.js-swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
				},
			}
		});
	}

	if (util.isDesktopAndAbove() && $(swiperDesktop).length) {
		var imageGridSwiper = new Swiper(swiperDesktop, {
			loop: false,
			slidesPerView: 3.5,
			simulateTouch: true,
			pagination: {
				el: '.js-swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
				},
			}
		});
	}

	$(swiperMobile + ', ' + swiperDesktop).each(function() {
		const numberOfItems = $(this).find('.swiper-slide').length;

		if ((util.isDesktopAndAbove() && numberOfItems <= 3)
		|| (util.isTabletAndBelow() && !util.isMobileAndBelow() && numberOfItems <= 2)
		|| (util.isMobileAndBelow() && numberOfItems <= 1)) {
			$(this).find('.js-swiper-pagination').hide();
		}
	});

	
	
	
}

/**
 * @function
 * @description Initializes the swiper in the Pagedesigner Tiles
 */
function initializePageDesignerSwiper() {
	var blockTileContainer = $('[data-js="tile-swiper"]');
	var centerSlides = blockTileContainer.hasClass('with-center-slides');

	if (!blockTileContainer.length) {
		return;
	}

	let mySwiper;
	const breakpoint = window.matchMedia('(min-width:768px)');

	const breakpointChecker = function () {
		if (breakpoint.matches === true) {
			if (mySwiper !== undefined) {
				if (mySwiper.length && mySwiper.length > 1) {
					mySwiper.forEach(function (swiper) {
						swiper.destroy(true, true);
					});
				}
				else {
					mySwiper.destroy(true, true);
				}
			}
			return;
		}

		return enableSwiper();
	};

	const enableSwiper = function () {
		var slidePerView = 'auto';
		var spaceBetween = 0;

		if (centerSlides) {
			slidePerView = 3;
			spaceBetween = 60;
		}

		mySwiper = new Swiper('[data-js="tile-swiper"]', {
			loop: true,
			slidesPerView: slidePerView,
			centeredSlides: true,
			spaceBetween: spaceBetween,
			a11y: true,
			keyboardControl: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
				},
			},
			navigation: {
				nextEl: '[data-js="product-tile__next"]',
				prevEl: '[data-js="product-tile__prev"]'
			}
		});
	};

	if (centerSlides) {
		return enableSwiper();
	}

	breakpoint.addListener(breakpointChecker);
	breakpointChecker();
}

/**
 * @function
 * @description Initializes the Page Designer banner carousel
 */

function initializePageDesignerBannerCarousel() {

	// Calculate height of the arrows based on image
	var arrowsPosition = function (currBannerCarousel) {
		var container = $(currBannerCarousel);
		var imageContainer = container.find($('.swiper-slide-active').find($('.banner-carousel__wrapper')));
		var prevButton = container.find($('[data-js="banner-carousel__prev"]'));
		var nextButton = container.find($('[data-js="banner-carousel__next"]'));
		var arrowsHeight = prevButton.height();
		var swiperImageHeight = imageContainer.height();
		var swiperButtonPos = (swiperImageHeight / 2) - (arrowsHeight / 2);
		nextButton.css('top', swiperButtonPos);
		prevButton.css('top', swiperButtonPos);
	};

	// Calculate height of the arrows based on image
	var paginationPosition = function (currBannerCarousel) {
		var container = $(currBannerCarousel);
		var imageContainer = container.find($('.swiper-slide-active').find($('.banner-carousel__wrapper')));
		var desktopPagination = container.find($('[data-js="desktop-carousel-pagination"]'));
		var swiperImageHeight = imageContainer.height();
		var paginationPosition = util.isSmallScreenSize() ? paginationPosition = (swiperImageHeight) : (swiperImageHeight) - 60 ;
		desktopPagination.css('top', paginationPosition);
	};

	// Calculate height of the arrows based on active slide
	function updateImageArrows(slideEl) {
		if (!slideEl) {
			var currentSwiper = this;
			slideEl = currentSwiper.slides[currentSwiper.realIndex];
		}
		if ($(slideEl).hasClass('swiper-slide-active')) {
			var currContainer = $(slideEl).closest($('[data-js="banner-carousel"]'))
			 arrowsPosition(currContainer);
			 paginationPosition(currContainer);
		}
	}

	if ($('[data-js="banner-carousel"]').length) {
		$('[data-js="banner-carousel"]').each(function (index, swiperEl) {
			var currentBannerCarousel = $('[data-js="banner-carousel"]')[index];
			var currentPaginationContainer = $(currentBannerCarousel).find($('.banner-carousel__pagination'));
			var wrapper = $(currentBannerCarousel).find($('.swiper-wrapper'));
			var sliderItemAmount = wrapper.children().length;
			var isOneSlide = sliderItemAmount === 1;

			var bannerCarousel = new Swiper(currentBannerCarousel, {
				autoplay: isOneSlide ? false : {
					delay: 5000,
					disableOnInteraction: true
				},
				loop: !isOneSlide,
				watchOverflow: true,
				autoHeight: true,
				keyboard: {
				  enabled: true,
				},
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 1,
				},
				pagination: {
					el: currentPaginationContainer,
					clickable: false,
					renderBullet: function (index, className) {
						var sliderItermWidth = 100 / sliderItemAmount;
						return '<span style="width:' + sliderItermWidth + '%" data-js="bullet" class="' + className + ' bullet-' + index + '"><b></b></span>';
					},
				},
				navigation: {
					nextEl: '[data-js="banner-carousel__next"]',
					prevEl: '[data-js="banner-carousel__prev"]'
				},
				on: {
					lazyImageReady: updateImageArrows,
					transitionStart: function() {
						arrowsPosition(currentBannerCarousel);
						paginationPosition(currentBannerCarousel);
					},
					transitionEnd: function() {
						arrowsPosition(currentBannerCarousel);
						paginationPosition(currentBannerCarousel);
						if ($(currentBannerCarousel).find('.swiper-slide-active .title.dark').length) {
							$(currentBannerCarousel).addClass('dark-arrows');
						}
						else {
							$(currentBannerCarousel).removeClass('dark-arrows');
						}
					},
					slideChange: function () {
						var activeIndex = this.realIndex;

						var bullets = $(this.el).find('[data-js="bullet"]');
						bullets.removeClass('full');

						for (var i = 0; i < activeIndex; i++) {
							var bulletElement = $(this.el).find('.bullet-' + i);
							if (bulletElement.length > 0) {
								bulletElement.addClass('full');
							}
						}
					},
					slideChangeTransitionStart: function() {
						var currentSwiper = this;
						var videoSlide = $(this.el).find('.swiper-slide-active').find('[data-js="pd-wistia-video"]');
						var isAutoPlayStopped = !this.autoplay.running;

						if (videoSlide.length > 0) {
							// since we have a video in this slide, we pause auto play until the end of the video
							currentSwiper.autoplay.stop();

							var videoId = videoSlide.attr('id');
							var videoDuration = video.startVideo(videoId, (isAutoPlayStopped ? null : currentSwiper));
							var currentActiveBullet = $(this.el).find('.swiper-pagination-bullet-active').find('b');

							if (!isAutoPlayStopped) {
								currentActiveBullet.attr('style', 'animation-duration: ' + videoDuration + 's;');
								currentActiveBullet.parent().removeClass('no-animation');

								var videPlayPauseButton = $(this.el).find('[data-js="pd-wistia-video-play-pause"]');
								videPlayPauseButton.on('click', function() {
									currentSwiper.autoplay.stop();
									currentActiveBullet.parent().addClass('no-animation');
								});
							}
							else {
								currentActiveBullet.parent().addClass('no-animation');
							}
						}
						else {
							var activeBullet = $(this.el).find('.swiper-pagination-bullet-active').find('b');
							activeBullet.attr('style', 'animation-duration: 5s;');
						}
					},
					autoplayStop: function() {
						var bullets = $(this.el).find('[data-js="bullet"]');
						bullets.addClass('no-animation');
					},
					autoplayStart: function() {
						var bullets = $(this.el).find('[data-js="bullet"]');
						bullets.removeClass('no-animation');
					}
				},
			});
		});
	}
}

/**
 * @function
 * @description Initializes the Page Designer Hero Slider
 */

function initializePageDesignerHeroSlider() {
	var heroSwiperContainer = $('[data-js="hero-slider"]');

	if (heroSwiperContainer.length) {
		$(heroSwiperContainer).each(function (index, swiperEl) {
			var currentHeroSwiper = heroSwiperContainer[index];
			var currentHeroPagination = $(currentHeroSwiper).find('.hero-slider__pagination');
			var currentNextEl = $(currentHeroSwiper).find('[data-js="hero-slider__next"]');
			var currentPrevEl = $(currentHeroSwiper).find('[data-js="hero-slider__prev"]');

			var heroSlider = new Swiper(currentHeroSwiper, {
				pagination: {
					el: currentHeroPagination,
					clickable: true,
					renderBullet: function (index, className) {
						return '<span class="' + className + '"></span>';
					},
				},
				navigation: {
					nextEl: currentNextEl,
					prevEl: currentPrevEl
				}
			});
		});
	}
}

/**
 * @function
 * @description Initializes the product tile swiper on PLP when second image exist
 */

function initializeProductTileSwiper() {
	var isIE11 = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
	var plpSwiper = ('[data-js="plp-product-slider"]');
	var productTileWrapper = $('[data-js="product-tile"]');
	var swiperWrapper = $('[data-js="plp-swiper-wrapper"]');
	if ($(plpSwiper).length && util.isSmallScreenSize() && !isIE11) {
		swiperWrapper.addClass('swiper-wrapper');
		productTileWrapper.addClass('product-tile__touch-device');
		var plpCarousel = new Swiper(plpSwiper, {
			loop: true,
			lazy: true,
			observer: true,
			on: {
				slideChange: function () {
					var activeIndex = this.realIndex;
					if (this.eventInitialized) {
						if (this.lastUsedIndex !== activeIndex) {
							var productId = $(this.el).closest('.product-tile').data('itemid');
							trackingHelper.pushBasicTrackingEvent('swiper', 'swiper', productId + ' - ' + activeIndex);
						}
					}
					else {
						this.eventInitialized = true;
					}
					this.lastUsedIndex = activeIndex;
				}
			}
		});
	}
}

/**
* @function
* @description Category Thumnail (Page Designer)
*/

function initializePageDesignerCategoryThumbnail() {
	var categoryThumbnail = ('[data-js="category-thumbnail"]');
	var thumbnailSize = $('.category-thumbnail__container').children().length;

	if (util.isMobile() && thumbnailSize <= 2) {
		return;
	}

	if ($(categoryThumbnail).length && util.isMobile()) {
		var slider = new Swiper(categoryThumbnail, {
			loop: false,
			slidesPerView: 'auto',
			pagination: {
				el: '.category-thumbnail__pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (index + 1) + '</span>';
				},
			}
		});
	}
}

/**
* @function
* @description Cart reminder
*/

function initializeCartReminder() {
	var cartReminder = ('[data-js="cart-reminder-slider"]');
	var arrowLeft = ('[data-js="cart-reminder__prev"]');
	var arrowRight = ('[data-js="cart-reminder__next"]');
	if ((!util.isMobile() && $(cartReminder).find('.cart-reminder__item').length <= 3)
		|| (util.isMobile() && $(cartReminder).find('.cart-reminder__item').length <= 2)) {
		$(arrowLeft).addClass('slider-disabled');
		$(arrowRight).addClass('slider-disabled');
		return;
	}
	if ($(cartReminder).length) {
		var slider = new Swiper(cartReminder, {
			loop: false,
			slidesPerView: 2,
			spaceBetween: 10,
			navigation: {
				nextEl: arrowRight,
				prevEl: arrowLeft
			},
			breakpoints: {
				767: {
					slidesPerView: 3
				}
			},
		});
	}
}

/**
 * @function
 * @description Initializes the PDP Swiper
 */
function initializePdpSwiper(isQuickView) {
	var swiperSelector = '.pdp-wrapper [data-js="pdp-slider"]';
	if (isQuickView) {
		swiperSelector = '#QuickViewDialog [data-js="pdp-slider"]';
	}

	if ($(swiperSelector).length) {
		var pdpSwiper = new Swiper(swiperSelector, {
			loop: true,
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 1,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (index + 1) + '</span>';
				},
			},
			navigation: {
				nextEl: '[data-js="swiper-button-next"]',
				prevEl: '[data-js="swiper-button-prev"]'
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			}
		});

		if ($('.swiper-zoom-slide').length === 0) {
			$('.product-main-slider').addClass('no-zoom-images');
		}
		else {
			$('.product-main-slider').removeClass('no-zoom-images');
		}

		pdpSwipers.mainImageSwiper = pdpSwiper;
		pdpSwiper.off('slideChange').on('slideChange', function () {
			image360EventsOnSlideChange(this);
		});

		commonPdpSwiperEvents();
		pdpZoomSwiperEvents(isQuickView);
	}
}

var headerUspSwiper = null;

function initializeHeaderUspSwiper() {
	var swiperSelector = document.querySelector('.c-header-top #usp-swiper-wrapper') ? '.c-header-top #usp-swiper-wrapper' : '[data-js="usp-swiper-wrapper"]';

	if ($(swiperSelector).length == 0) {
		return;
	}

	headerUspSwiper = new Swiper(swiperSelector, {
		loop: true,
		autoHeight: true,
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		navigation: {
			nextEl: '[data-js="usp-banner-next"]',
			prevEl: '[data-js="usp-banner-prev"]'
		},
		on: {
			init: function () {
				arrangeSwiperFocusableElements(swiperSelector);
			}
		}
	});

	// we find the active slider item and set its links as focusable only
	// , otherwise the slider items which are not current items can be also focused even if not visible
	headerUspSwiper.on('slideChangeTransitionEnd', function () {
		arrangeSwiperFocusableElements(swiperSelector);
	});
}

function arrangeSwiperFocusableElements(selector) {
	// for all link elements (anchor) of the slider we make them un-focusable first (otherwise the slider items which are not current items can be also focused even if not visible)
	// we also set an attribute data-swiper="true" to use later in the accesiblity logic
	$(selector).find('a').attr('data-swiper', true).attr('aria-hidden', 'true')
		.attr('tabindex', '-1');

	var isPlp = $('.pt_product-search-result').length > 0;

	// then we find the link elements (anchor) of currently active slider and we set them focusable
	var focusElements = $(selector).find('.swiper-slide-active').find('a');
	focusElements.removeAttr('data-swiper').removeAttr('aria-hidden').attr('tabindex', isPlp ? '1' : '0');
}

function commonPdpSwiperEvents() {
	$(document).off('click', '[data-js="image360-overlay"]').on('click', '[data-js="image360-overlay"]', function () {
		$(this).hide();
		$('[data-js="image360-text"]').hide();
	});
}
/**
 * @function
 * @description Initializes the PDP Swiper
 */
function initializeRightPdpSwiper() {
	// we don't have slider for desktop
	if (util.isDesktopAndAbove()) {
		commonPdpSwiperEvents();
	 	pdpZoomSwiperEvents();
		return;
	}

	var swiperSelector = '.pdp-wrapper [data-js="pdp-images-slider"]';

	var rightPdpSwiper = new Swiper(swiperSelector, {
		slidesPerView: 1,
		loop: false,
		watchOverflow: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		a11y: true,
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 1,
		},
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		navigation: {
			nextEl: '[data-js="swiper-button-next"]',
			prevEl: '[data-js="swiper-button-prev"]'
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		}
	});

	pdpSwipers.mainImageSwiper = rightPdpSwiper;

	rightPdpSwiper.on('init', function() {
		// manually destroying 360 on init.
		// window.CI360.destroy() not working on IE
		if (this.$el.find('.cloudimage-360') && window.CI360 && util.isTabletAndAbove()) {
			this.$el.find('.cloudimage-360').removeClass('initialized');
			this.$el.find('.cloudimage-360').children().remove();
		}
	});

	rightPdpSwiper.on('slideChange', function () {
		image360EventsOnSlideChange(this);
		$('[data-js="product-main-swiper"]').trigger('blur');

		// When 360 is destroyed, arrows needs to be added manually
		var image360Arrows = '<div class="image360-arrows-indication only-desktop"><span class="cloudimage-360-prev"></span><span class="cloudimage-360-next"></span></div>';
		if (util.isDesktopAndAbove() && window.CI360) {
			var thumb360 = $('.image360-thumbnail');
			if (thumb360.hasClass('swiper-slide-thumb-active')) {
				this.$el.find('.cloudimage-360').append(image360Arrows);
				window.CI360.init();
				tooltip.image360Tooltip();
			}
			else {
				this.$el.find('.cloudimage-360').removeClass('initialized');
				this.$el.find('.cloudimage-360').children().remove();
			}
		}
	});

	commonPdpSwiperEvents();
	pdpZoomSwiperEvents();
}

/**
 * @function
 * @description Initializes the pdp zoom swiper swiper open and close events
 */
function pdpZoomSwiperEvents(isQuickView) {
	var imagesWrapper = $('.pt_product-detail .pdp-gallery-main .swiper-wrapper [data-js="pdp-product-image"]');
	var pdpZoomSwiperClose = $('.product-zoom-slider-wrapper [data-js="control-close"]');

	// Keyboard accessibility - Product main swiper - triggering event for open Zoom swiper
	imagesWrapper.on('click', function(e) {
		if (e.keyCode === keyboard.ENTER_KEY || e.type === 'click') {
			pdpOpenZoomSwiperFromMainSwiper(this, isQuickView);

			$('body').addClass('zoom-slider-keyboard-interaction');
			$('.pt_product-detail .c-product-slider .product-main-slider .swiper-button-next').removeAttr('tabIndex');
			$('.pt_product-detail .c-product-slider .product-main-slider .swiper-wrapper').removeAttr('tabIndex');
			$('.pt_product-detail .c-product-slider .product-zoom-slider .swiper-button-prev').trigger('focus');
		}
	});


	// Keyboard accessibility - Zoom swiper - triggering event for closing zoom swiper
	pdpZoomSwiperClose.off('keydown click').on('keydown click', function (e) {
		//close zoom swiper
		if (e.keyCode === keyboard.ENTER_KEY || e.type === 'click') {
			$('[data-js="product-zoom-slider"]').hide(0, function() {
				pdpSwipers.zoomImageSwiper.destroy();
			});

			$('body').removeClass('zoom-is-active zoom-slider-keyboard-interaction');
			$('html').removeClass('zoom-is-active');

			$('.pt_product-detail .c-product-slider .product-main-slider .swiper-wrapper').attr('tabIndex', '0');
			$('.pt_product-detail .c-product-slider .product-main-slider .swiper-button-next').attr('tabIndex', '0');
			$('.pt_product-detail .c-product-slider .product-main-slider .swiper-button-next').trigger('focus');
		}
	});
}

/**
 * @function
 * @description Trigger the Zoom Swiper
 */

function pdpOpenZoomSwiperFromMainSwiper(element, isQuickView) {
	if ($(element).parents().is('.ui-dialog')) {
		return;
	}

	if ($(element).children().filter('.swiper-slide-active').hasClass('slide-image360')
		|| $(element).children().filter('.swiper-slide-active').hasClass('swiper-no-swiping')) {
		return;
	}

	// if no zoom images exist, avoid the dialog
	if ($('.product-main-slider').hasClass('no-zoom-images')) {
		return;
	}

	$('[data-js="product-zoom-slider"]').show();
	$('body').addClass('zoom-is-active');
	$('html').addClass('zoom-is-active');

	$('[data-js="product-zoom-slider"]').off('click').on('click', function(e) {
		var targetElement = $(e.target);

		if (targetElement.hasClass('swiper-button-prev') || targetElement.hasClass('swiper-button-next')) {
			return;
		}

		$('[data-js="product-zoom-slider"]').hide(0, function() {
			pdpSwipers.zoomImageSwiper.destroy();
			$('body').removeClass('zoom-is-active zoom-slider-keyboard-interaction');
			$('html').removeClass('zoom-is-active');
		});
	});

	removeStickyHeader();

	var slideToIndex = $(element).data('index');

	pdpZoomSwiper(isQuickView, slideToIndex);

	accessibility.focusTrap($('[data-js="product-zoom-slider"]'));
}


function removeStickyHeader() {
	var $topDivs = $('body').children('div[class^="pt_"]');
	var $main = $topDivs.children('.c-main');
	$topDivs.removeClass('sticky-header');
	$main.css('padding-top', 0);
}

/**
 * @function
 * @description Handle the text resource for Zoom swiper buttons
 */

function setToggleButtonIcon(pdpSwiperZoom) {
	var pdpZoomControlPlus = $('[data-js="control-plus"]');

	if (pdpSwiperZoom.zoom.scale > 1) {
		pdpZoomControlPlus.addClass('plus-is-active');
		pdpZoomControlPlus.text(Resources.ZOOM_MIN);
	}
	else {
		pdpZoomControlPlus.removeClass('plus-is-active');
		pdpZoomControlPlus.text(Resources.ZOOM_PLUS);
	}
}

/**
 * @function
 * @description Initializes the swiper in the full screen
 */

function pdpZoomSwiper(isQuickView, slideIndex=0) {
	if (isQuickView) {
		return;
	}

	var sliderZoom = '[data-js="slider-zoom"]';
	var sliderLoop = false;

	if ($(sliderZoom).find('.swiper-slide').length > 1) {
		sliderLoop = true;
	}

	if ($('[data-js="slider-zoom"]').length) {
		var pdpSwiperZoom = new Swiper(sliderZoom, {
			loop: sliderLoop,
			lazy: {
				// tell swiper to load images before they appear
				loadPrevNext: true,
				// amount of images to load
				loadPrevNextAmount: 1,
			},
			zoom: {
				maxRatio: 2
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			centeredSlides: true,
			slidesPerView: 1,
			initialSlide: slideIndex,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			on: {
				init: function () {
					markActiveThumb(this.realIndex);
					if (this.$el.find('.cloudimage-360') && window.CI360) {
						//reinitialize the cloudimage-360 CI360 on zoom view
						this.$el.find('.cloudimage-360').removeClass('initialized');
						this.$el.find('.cloudimage-360').children().remove();
						window.CI360.init();
					}
				},
			}
		});

		pdpSwiperZoom.off('slideChange').on('slideChange', function () {
			$('[data-js="control-plus"]').removeClass('plus-is-active');

			//image360 overlay
			image360EventsOnSlideChange(this);
			//set class is-active to selected thumb
			markActiveThumb(this.realIndex);

			//initialize images360 only when needed
			if (util.isTabletAndAbove() && window.CI360) {
				var thumbnail360 = $('.product-zoom-slider-wrapper .image360-thumbnail');
				if (thumbnail360.hasClass('is-active')) {
					window.CI360.destroy();
					window.CI360.init();
				}
			}
		});

		pdpSwipers.zoomImageSwiper = pdpSwiperZoom;

		commonPdpSwiperEvents();

		var closeZoom = function () {
			$('[data-js="product-zoom-slider"]').hide();
			$('body').removeClass('zoom-is-active');
			$('html').removeClass('zoom-is-active');
		};

		$(document).off('click', '[data-js="control-close"]').on('click', '[data-js="control-close"]', closeZoom);
		$(document).on('keyup', function (e) {
			if (e.keyCode === keyboard.ESC_KEY && $('body').hasClass('zoom-is-active')) {
				closeZoom();
				$('body').removeClass('zoom-slider-keyboard-interaction');

				$('.pt_product-detail .c-product-slider .product-main-slider .swiper-wrapper').attr('tabIndex', '0');
				$('.pt_product-detail .c-product-slider .product-main-slider .swiper-wrapper').trigger('focus');
				$('.pt_product-detail .c-product-slider .product-main-slider .swiper-button-next').attr('tabIndex', '0');
			}
		});

		$(document).off('keydown click', '[data-js="slide-thumb"]').on('keydown click', '[data-js="slide-thumb"]', function (e) {
			if (e.keyCode === keyboard.ENTER_KEY || e.type === 'click') {
				pdpSwipers.zoomImageSwiper.slideToLoop($(this).data('index'));
			}
		});
	}
}

/**
 * @function
 * @description add is-active class to active thumb img in pdp zoom swiper
 */
function markActiveThumb(index) {
	if (index >= 0) {
		var swiperThumbs = $('[data-js="slide-thumb"]'),
			selectedThumb = swiperThumbs.filter('[data-index="' + index + '"]').addClass('is-active');

		swiperThumbs.not(selectedThumb).removeClass('is-active');
	}
}

/**
 * @function
 * @description 360 image slider
 */
var image360EventsOnSlideChange = function (swiper) {
	var image360Slide = $(swiper.slides).filter('.slide-image360');
	if (image360Slide.length > 0) {
		var activeSlide = $(swiper.slides[swiper.activeIndex]);
		var $mainPdpSwiper = $('[data-js="product-main-swiper"]');
		if (activeSlide.hasClass('slide-image360')) {
			// Set the title to nothing so we don't see 'Zoom in' text
			$mainPdpSwiper.attr('title', '');
		}
		else {
			if (util.isMobile()) {
				$('.image360-overlay').show();
			}
			// Retrieve the title from the temporary attribute
			var tempTitle = $mainPdpSwiper.data('tempTitle');
			// Return the title to what it was
			$mainPdpSwiper.attr('title', tempTitle);
		}
	}
};

exports.initializeHeaderUspSwiper = initializeHeaderUspSwiper;
exports.initializePdpSwiper = initializePdpSwiper;
exports.initializeRightPdpSwiper = initializeRightPdpSwiper;
exports.initializeProductTileSwiper = initializeProductTileSwiper;
exports.initShoeCareRecommendationSwiper = initShoeCareRecommendationSwiper;
exports.initializeProductRecommendationSwiper = initializeProductRecommendationSwiper;
exports.initShopTheLookRecommendationSwiper = initShopTheLookRecommendationSwiper;
exports.initializeCartReminder = initializeCartReminder;
